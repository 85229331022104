<template>
  <b-img
    :src="skin === 'light' ? appIconImage : appIconImageDark"
    alt="logo"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { skin } = useAppConfig()
    // App Name
    const { appIconImage, appIconImageDark } = $themeConfig.app
    return {
      appIconImage,
      appIconImageDark,
      // Skin
      skin,
    }
  },
}
</script>

<style>

</style>
