<template>
  <div>
    <b-form-checkbox
      name="check-language"
      switch
      @change="onLanguageChange"
    >
      <span class="switch-icon-left">
        PT
      </span>
      <span class="switch-icon-right">
        EN
      </span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: { BFormCheckbox },
  methods: {
    onLanguageChange(event) {
      this.$i18n.locale = event ? 'pt_br' : 'en_uk'
    },
  },
}
</script>

<style>

</style>
