<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Register-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <div style="position:absolute; top: 10px">
          <LanguageSwitcher />
        </div>
        <b-col class="px-xl-2 mx-auto">
          <vuexy-logo class="mb-2" />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('register.title_main') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('register.title_secondary') }}
          </b-card-text>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="`${$t('register.form.register_type')}`"
                    label-for="register-type-person"
                  >
                    <div class="d-flex">
                      <b-form-radio
                        v-model="selectedRegisterType"
                        plain
                        name="register-type-person"
                        value="person"
                        @change="onRegisterTypeChange"
                      >
                        {{ $t('register.form.register_type_person') }}
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedRegisterType"
                        plain
                        name="register-type-company"
                        value="company"
                        class="ml-1"
                        @change="onRegisterTypeChange"
                      >
                        {{ $t('register.form.register_type_company') }}
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="selectedRegisterType ==='company'"
                  cols="12"
                  md="8"
                >
                  <!-- userName -->
                  <b-form-group
                    :label="`${$t('register.form.company_name')}`"
                    label-for="company-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="companyName"
                      rules="required"
                    >
                      <b-form-input
                        id="company-name"
                        v-model="companyName"
                        name="company-name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="company SA"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- userName -->
                  <b-form-group
                    :label="`${$t('register.form.name')}`"
                    label-for="register-userName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="UserName"
                      rules="required"
                    >
                      <b-form-input
                        id="register-userName"
                        v-model="userName"
                        name="register-userName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="johndoe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- email -->
                  <b-form-group
                    :label="`${$t('register.form.email')}`"
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="userEmail"
                        name="register-email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- telefone -->
                  <b-form-group
                    :label="`${$t('register.form.phone')}`"
                    label-for="register-telefone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="register-telefone"
                      rules="required"
                    >
                      <b-form-input
                        id="register-telefone"
                        v-model="userTelefone"
                        v-mask="'+## (##) #####-####'"
                        name="register-telefone"
                        type="tel"
                        :state="errors.length > 0 ? false : null"
                        placeholder="+XX (XX) XXXXX-XXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- password -->
                  <b-form-group
                    :label="`${$t('register.form.password')}`"
                    label-for="register-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      vid="password"
                      name="Password"
                      rules="required|min:6"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="register-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- confirm password -->
                  <b-form-group
                    :label="`${$t('register.form.confirm_password')}`"
                    label-for="confirmPassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="confirmPassword"
                      rules="required|confirmed:password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="confirmPassword"
                          v-model="confirmPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="confirmPassword"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- type document_number -->
                  <b-form-group
                    :label="`${$t('register.form.document_type')}`"
                    label-for="document_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="document_type"
                      rules="required"
                    >
                      <b-form-select
                        id="document_type"
                        v-model="document_type"
                        name="document_type"
                        :options="documentTypeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- document_number -->
                  <b-form-group
                    :label="`${$t('register.form.document_number')}`"
                    label-for="document_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="document_number"
                      rules="required|min:6"
                    >
                      <b-form-input
                        id="document_number"
                        v-model="document_number"
                        name="document_number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="xxxxxxxxxxxx"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <label for="wildcard">{{ $t('register.form.document_file') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="document_file"
                    rules="required"
                  >
                    <b-form-file
                      id="wildcard"
                      v-model="document_file"
                      name="document_file"
                      accept="image/*, .pdf"
                      :browse-text="`${$t('register.form.select_file')}`"
                      :placeholder="`${$t('register.form.no_file_selected')}`"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="status"
                      name="checkbox-1"
                    >
                      {{ $t('register.form.check_policy_1') }}
                      <b-link>{{ $t('register.form.check_policy_2') }}</b-link>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="!status"
                    @click.prevent="validationForm"
                  >
                    {{ $t('register.form.button_submit') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <p class="mt-2">
            <span>
              {{ $t('register.already_registered') }}
            </span><br>
            <b-link :to="{ name: 'login' }">
              <span>{{ $t('register.do_login') }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-2"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardText,
  BFormFile,
  BImg,
  BFormSelect,
  BFormRadio,
} from 'bootstrap-vue'
import {
  required,
  email,
  min,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { registerService } from '@/services/auth/registerService'
import { mask } from 'vue-the-mask'

import LanguageSwitcher from '@/layouts/components/LanguageSwitcher.vue'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  directives: { mask },
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BImg,
    // validations
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
    BFormSelect,
    BFormRadio,
    LanguageSwitcher,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      registerService: registerService(this.$http),
      selectedRegisterType: 'person',
      status: false,
      companyName: '',
      userName: '',
      userEmail: '',
      userTelefone: '',
      password: '',
      confirmPassword: '',
      document_type: '',
      document_number: '',
      document_file: [],
      sideImg: require('@/assets/images/bg_login.svg'),
      // validation
      required,
      email,
      min,
      options: {
        person: [
          { value: '', text: 'Selecione', disabled: true },
          { value: 'RG', text: 'RG' },
          { value: 'CPF', text: 'CPF' },
          { value: 'PASSAPORTE', text: 'PASSAPORTE' },
          { value: 'ID', text: 'ID' },
        ],
        company: [
          { value: 'CNPJ', text: 'CNPJ', disabled: true },
        ],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/bg_login.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    documentTypeOptions() {
      return this.selectedRegisterType === 'person' ? this.options.person : this.options.company
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          const arquivo = await toBase64(this.document_file)
          const data = {
            name: this.userName,
            email: this.userEmail,
            password: this.password,
            password_confirmation: this.confirmPassword,
            register_type: this.selectedRegisterType,
            document: [{
              type: this.document_type,
              number: this.document_number,
              file: arquivo,
            }],
            chosen_plan: 1,
            phone: this.userTelefone,
          }

          if (this.selectedRegisterType === 'company') {
            data.company_name = this.companyName
          }

          this.registerService.post(data).then(response => {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Seu cadastro foi efetuado. Aguarde o e-mail de confirmação para acessar ao sistema!',
            }).catch(err => {
              console.log(err.response)
            })

            // setTimeout(() => {
            //   this.$router.push({ name: 'login' })
            // }, 5000)
          }).catch(err => {
            if (err.response) {
              const message = this.getFirstErrorMessage(err.response.data.errors)

              this.$swal({
                icon: 'error',
                title: 'Operação não realizada!',
                text: `ERRO: ${message}`,
              })
            }
          })
        }
      })
    },
    onRegisterTypeChange() {
      this.document_type = this.selectedRegisterType === 'company' ? 'CNPJ' : ''
    },
    // temporary solution to show only the first error
    getFirstErrorMessage(errors) {
      const key = Object.keys(errors)[0]
      return errors[key]
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
