var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"8"}},[_c('div',{staticStyle:{"position":"absolute","top":"10px"}},[_c('LanguageSwitcher')],1),_c('b-col',{staticClass:"px-xl-2 mx-auto"},[_c('vuexy-logo',{staticClass:"mb-2"}),_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('register.title_main'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('register.title_secondary'))+" ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.register_type'))),"label-for":"register-type-person"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-radio',{attrs:{"plain":"","name":"register-type-person","value":"person"},on:{"change":_vm.onRegisterTypeChange},model:{value:(_vm.selectedRegisterType),callback:function ($$v) {_vm.selectedRegisterType=$$v},expression:"selectedRegisterType"}},[_vm._v(" "+_vm._s(_vm.$t('register.form.register_type_person'))+" ")]),_c('b-form-radio',{staticClass:"ml-1",attrs:{"plain":"","name":"register-type-company","value":"company"},on:{"change":_vm.onRegisterTypeChange},model:{value:(_vm.selectedRegisterType),callback:function ($$v) {_vm.selectedRegisterType=$$v},expression:"selectedRegisterType"}},[_vm._v(" "+_vm._s(_vm.$t('register.form.register_type_company'))+" ")])],1)])],1),(_vm.selectedRegisterType ==='company')?_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.company_name'))),"label-for":"company-name"}},[_c('validation-provider',{attrs:{"name":"companyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name","name":"company-name","state":errors.length > 0 ? false : null,"placeholder":"company SA"},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1531481816)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.name'))),"label-for":"register-userName"}},[_c('validation-provider',{attrs:{"name":"UserName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-userName","name":"register-userName","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.email'))),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.phone'))),"label-for":"register-telefone"}},[_c('validation-provider',{attrs:{"name":"register-telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+## (##) #####-####'),expression:"'+## (##) #####-####'"}],attrs:{"id":"register-telefone","name":"register-telefone","type":"tel","state":errors.length > 0 ? false : null,"placeholder":"+XX (XX) XXXXX-XXXX"},model:{value:(_vm.userTelefone),callback:function ($$v) {_vm.userTelefone=$$v},expression:"userTelefone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.password'))),"label-for":"register-password"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.confirm_password'))),"label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"confirmPassword","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.document_type'))),"label-for":"document_type"}},[_c('validation-provider',{attrs:{"name":"document_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"document_type","name":"document_type","options":_vm.documentTypeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.document_type),callback:function ($$v) {_vm.document_type=$$v},expression:"document_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":("" + (_vm.$t('register.form.document_number'))),"label-for":"document_number"}},[_c('validation-provider',{attrs:{"name":"document_number","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document_number","name":"document_number","state":errors.length > 0 ? false : null,"placeholder":"xxxxxxxxxxxx"},model:{value:(_vm.document_number),callback:function ($$v) {_vm.document_number=$$v},expression:"document_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":"wildcard"}},[_vm._v(_vm._s(_vm.$t('register.form.document_file')))]),_c('validation-provider',{attrs:{"name":"document_file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"wildcard","name":"document_file","accept":"image/*, .pdf","browse-text":("" + (_vm.$t('register.form.select_file'))),"placeholder":("" + (_vm.$t('register.form.no_file_selected')))},model:{value:(_vm.document_file),callback:function ($$v) {_vm.document_file=$$v},expression:"document_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('register.form.check_policy_1'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('register.form.check_policy_2')))])],1)],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":!_vm.status},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('register.form.button_submit'))+" ")])],1)],1)],1)],1),_c('p',{staticClass:"mt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('register.already_registered'))+" ")]),_c('br'),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(_vm._s(_vm.$t('register.do_login')))])])],1)],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-2",attrs:{"lg":"4"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }